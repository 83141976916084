import { mapGetters, mapActions } from 'vuex';
import { apolloClient } from '@/apollo';
import GET_DOMAIN_INFO from '@/graphql/GetDomainInfo.gql';
import { getAccountIdFromCookie } from '@/util';
import { track, trackIntercom } from '@/services/xray';
import LogRocket from 'logrocket';
import runtimeConfig from '@/config/runtime';
import platformMixin from '@/mixins/codeinsert/platform';
import { createDCCampaignFromCache } from '@/utils/createDCCampaign';
import CHECK_SHOPIFY_APP_EMBED_STATUSES from '@/graphql/CheckShopifyAppEmbedStatuses.gql';

export default {
  mixins: [platformMixin],
  data: () => ({
    errorCount: 0,
    status: null,
    loading: false,
  }),
  computed: {
    ...mapGetters(['databaseId', 'isNewFrequencyRuleEnabled']),
    isOnboarding() {
      return !this.$route.path.includes('/settings/code-insert');
    },
    showHeader() {
      if (this.isOnboarding) {
        return true;
      }
      return !this.$route.name.endsWith('code-insert-domain');
    },
    pageId() {
      if (this.$route.name.endsWith('code-insert-domain')) {
        return 'insert-code-domain';
      }
      if (this.$route.name.endsWith('code-insert-platform')) {
        return 'insert-code-platform';
      }
      if (this.$route.name.endsWith('code-insert-platform-setup')) {
        return 'insert-code-setup';
      }
    },
    platform() {
      return this.$route.params.platform || '';
    },
    domain() {
      return this.$route.query.domain;
    },
    showCheckButton() {
      return this.$route.name.endsWith('code-insert-platform-setup');
    },
    showNext() {
      return this.isOnboarding && this.$route.name.includes('code-insert-success');
    },
    showFinish() {
      return !this.isOnboarding && this.$route.name.includes('code-insert-success');
    },
    showSkip() {
      return this.isOnboarding && !this.$route.name.includes('code-insert-success');
    },
  },

  watch: {
    domain(value) {
      if (!value) {
        this.errorCount = 0;
      }
    },
  },

  methods: {
    ...mapActions(['createDCCampaign']),
    goBack() {
      this.$router.go(-1);
    },
    headerEvents(event) {
      const events = {
        back: this.navigateBack,
        finish: this.navigateToNext,
        verify: this.verifyCodeInsert,
      };
      events[event]();
    },

    async navigateToNext() {
      if (this.isCreateFlow) {
        const url = new URL(this.$route.query.url);
        const dcURL = await createDCCampaignFromCache({
          url: this.$route.query.url,
          userId: this.$route.params.userId,
          domain: this.domain,
          lastEditUrl: url.pathname,
          needCacheChanges: true,
          frequencyEnabled: this.isNewFrequencyRuleEnabled,
        });
        window.location.href = dcURL;
        return;
      }
      const { campaign: id } = this.$route.query;
      const userId = getAccountIdFromCookie();
      // if the user comes from the campaign detail page
      if (id) {
        this.$router.push({ name: 'campaign_variants', params: { id, userId } });
      } else {
        this.$router.push({ name: 'dashboard' });
      }
    },

    navigateBack() {
      this.$router.go(-1);
    },

    async verifyShopifyEmbed() {
      const {
        data: { appEmbedStatuses },
      } = await this.$apollo.mutate({
        mutation: CHECK_SHOPIFY_APP_EMBED_STATUSES,
        variables: {
          myshopifyDomains: [this.domain],
        },
      });
      return appEmbedStatuses[0];
    },

    async verifyDomainInfo() {
      const {
        data: {
          siteInfo: { inserted, om: insertedAccountId },
        },
      } = await apolloClient.query({
        query: GET_DOMAIN_INFO,
        variables: {
          domain: this.domain,
          improved: true,
        },
      });
      return { inserted, insertedAccountId };
    },

    async handleVerificationSuccess() {
      this.status = null;
      const name = this.isOnboarding ? 'code-insert-success' : 'settings-code-insert-success';
      this.$router.push({ name, query: { ...this.$route.query } });
    },

    handleVerificationError(_) {
      this.increaseError();
      this.status = 'error';

      if (this.errorCount === 2) {
        this.trackVerificationError();
      }
    },

    trackVerificationError() {
      const locale = this.$i18n.locale;
      trackIntercom(`code-insert-verify-error-${locale}`, { platform: this.platform });

      const logRocketProject = runtimeConfig.VUE_APP_LOGROCKET_PROJECT;
      const logRocketInited = window._logRocketInited;
      if (logRocketProject && logRocketInited) {
        LogRocket.track('code-insert-verify-error');
      }
    },

    async verifyCodeInsert() {
      if (this.loading) return;

      this.status = 'loading';
      try {
        if (this.platform === 'shopify') {
          const appEmbedStatus = await this.verifyShopifyEmbed();
          if (!appEmbedStatus.disabled) {
            await this.handleVerificationSuccess();
          } else {
            this.handleVerificationError();
          }
        } else {
          const { inserted, insertedAccountId } = await this.verifyDomainInfo();

          if (inserted && insertedAccountId === this.databaseId) {
            await this.handleVerificationSuccess();
          } else {
            this.handleVerificationError();
          }
        }
      } catch (error) {
        this.handleVerificationError(error);
      }
    },

    increaseError() {
      ++this.errorCount;
      track('insert-code-checkNow-error', { platform: this.platform });
    },
  },
};
